import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            <div className="container lg:px-0 py-20">
                <Title>Curs Presencial Guàrdia Urbana de Barcelona 2024</Title>
                <Text>
                    El Curs Presencial per la Guàrdia Urbana de Barcelona és un Best Seller quan
                    s'apropa la convocatòria.
                    <br />
                    <br />
                    El curs conté les explicacions detallades de tot el temari i trucs pels
                    psicotècnics.
                    <br />
                    <br />
                    És l'únic curs presencial que et dona accés a un petit campus online i a la guia
                    de Guàrdia Urbana de Barcelona de iOpos de regal.
                    <br />
                    <br />
                    El nostre mètode és totalment diferent de la resta d'acadèmies, ja que tots els
                    cursos són intensius i ens centrem en els continguts que realment són importants
                    i successibles de sortir a l'examen.
                    <br />
                    <br />
                    Tots els alumnes comencen i finalitzen junts el curs, d'aquesta manera
                    assoleixen al màxim tots els continguts.
                    <br />
                    <br />
                    Amb aquesta metodologia obtenim els millors resultats any rere any.
                    <br />
                    <br />
                    Un cop ple el curs, es fa un grup de WhatsApp de tots els alumnes i els
                    professors, això ens permet tenir una relació constant on mai us trobareu sols.
                    <br />
                    <br />
                    El curs s'imparteix a Hospitalet de Llobregat.
                    <br />
                    <br />
                    Al C/ Martí Codolar núm. 18.
                    <br />
                    <br />A mesura que s'apropi la nova convocatòria de la Guàrdia Urbana de
                    Barcelona tornarem a portar aquesta formació
                </Text>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/KDxAN7mtYKI"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    ></iframe>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
